import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfMinute from "date-fns/endOfMinute";
import { datePickerSelectors } from "shared/testConstants";
import { endOfDay } from "date-fns";
import { addLocalTZOffset, shiftDateByTimezoneOffset } from "libs/timezone/timezoneUtils";

export enum TimeRangeEnum {
  TODAY = "today",
  LAST_24_HOURS = "last24hours",
  WEEK = "week",
  LAST_7_DAYS = "last7days",
  MONTH = "month",
  LAST_30_DAYS = "last30days",
  LAST_90_DAYS = "90days",
  LAST_180_DAYS = "180days",
  YEAR = "year",
  ANYTIME = "anytime",
}

export const timerangeLabels: Record<string, string> = {
  [TimeRangeEnum.TODAY]: "Today",
  [TimeRangeEnum.LAST_24_HOURS]: "Last 24 hours",
  [TimeRangeEnum.WEEK]: "This week",
  [TimeRangeEnum.LAST_7_DAYS]: "Last 7 days",
  [TimeRangeEnum.MONTH]: "This month",
  [TimeRangeEnum.LAST_30_DAYS]: "Last 30 days",
  [TimeRangeEnum.LAST_90_DAYS]: "Last 90 days",
  [TimeRangeEnum.LAST_180_DAYS]: "Last 180 days",
  [TimeRangeEnum.YEAR]: "This year",
  [TimeRangeEnum.ANYTIME]: "Anytime",
};

export function todayUTC() {
  return addLocalTZOffset(new Date());
}

export function getAnyTimeStartDate(timezone?: string) {
  return timezone
    ? startOfDay(
        subDays(shiftDateByTimezoneOffset(addLocalTZOffset(new Date()), timezone), 1999 * 3)
      )
    : startOfDay(subDays(new Date(), 1999 * 3));
}

export function getTimeRangeDates(type: TimeRangeEnum, timezone?: string) {
  const today = () => (timezone ? shiftDateByTimezoneOffset(todayUTC(), timezone) : new Date());

  switch (type) {
    case TimeRangeEnum.TODAY:
      return {
        startDate: startOfDay(today()),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.LAST_24_HOURS:
      return {
        startDate: subDays(endOfMinute(today()), 1),
        endDate: endOfMinute(today()),
      };
    case TimeRangeEnum.WEEK:
      return {
        startDate: startOfDay(startOfWeek(today())),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.LAST_7_DAYS:
      return {
        startDate: startOfDay(subDays(today(), 7)),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.MONTH:
      return {
        startDate: startOfMonth(today()),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.LAST_30_DAYS:
      return {
        startDate: startOfDay(subDays(today(), 30)),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.LAST_90_DAYS:
      return {
        startDate: startOfDay(subDays(today(), 90)),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.LAST_180_DAYS:
      return {
        startDate: startOfDay(subDays(today(), 180)),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.YEAR:
      return {
        startDate: startOfYear(today()),
        endDate: endOfDay(today()),
      };
    case TimeRangeEnum.ANYTIME:
      return {
        startDate: getAnyTimeStartDate(timezone),
        endDate: endOfDay(today()),
      };
  }
}

// value is function because we need to have new Date at the click moment in case user was away for 2 days

export function getTimerangeConfig(
  isAnytimeEnabled?: boolean,
  timezone?: string,
  customTimeAllowedInRange?: number
) {
  const timerangeConfig = [
    {
      label: timerangeLabels[TimeRangeEnum.TODAY],
      value: () => getTimeRangeDates(TimeRangeEnum.TODAY, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.day,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.LAST_24_HOURS],
      value: () => getTimeRangeDates(TimeRangeEnum.LAST_24_HOURS, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.day,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.WEEK],
      value: () => getTimeRangeDates(TimeRangeEnum.WEEK, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.week,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.LAST_7_DAYS],
      value: () => getTimeRangeDates(TimeRangeEnum.LAST_7_DAYS, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.week,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.MONTH],
      value: () => getTimeRangeDates(TimeRangeEnum.MONTH, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.month,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.LAST_30_DAYS],
      value: () => getTimeRangeDates(TimeRangeEnum.LAST_30_DAYS, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.week,
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.LAST_90_DAYS],
      value: () => getTimeRangeDates(TimeRangeEnum.LAST_90_DAYS, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges["90Days"],
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.LAST_180_DAYS],
      value: () => getTimeRangeDates(TimeRangeEnum.LAST_180_DAYS, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges["180Days"],
      },
    },
    {
      label: timerangeLabels[TimeRangeEnum.YEAR],
      value: () => getTimeRangeDates(TimeRangeEnum.YEAR, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.year,
      },
    },
  ];

  if (isAnytimeEnabled) {
    timerangeConfig.push({
      label: timerangeLabels[TimeRangeEnum.ANYTIME],
      value: () => getTimeRangeDates(TimeRangeEnum.ANYTIME, timezone),
      props: {
        "data-testid": datePickerSelectors.ranges.anytime,
      },
    });
  }

  if (timezone) {
    timerangeConfig.forEach((config) => {
      const oldValueFunc = config.value;
      config.value = () => {
        const { startDate, endDate } = oldValueFunc();
        if (
          !customTimeAllowedInRange ||
          endDate.getTime() - startDate.getTime() > customTimeAllowedInRange
        ) {
          return {
            startDate: shiftDateByTimezoneOffset(startDate, timezone),
            endDate: shiftDateByTimezoneOffset(endOfDay(todayUTC()), timezone),
          };
        }
        return { startDate, endDate };
      };
    });
  }

  return timerangeConfig;
}
