import { ENV } from "runenv";
import { PermissionResources, PermissionActions } from "./types";

export type ResourceDescription = { name: string; description: string } & Partial<
  Record<PermissionActions, { linkedWith?: string[] }>
>;

export const resourceDescriptions: Record<PermissionResources, ResourceDescription> = {
  directory_user_details: {
    name: "End User Details",
    description: "Full information about end-users, including data from directory integrations",
  },
  dataset: {
    name: "Dataset",
    description: "Dataset definitions",
  },
  policy: {
    name: "Policy",
    description: "Policies definitions",
  },
  dataset_policy_template: {
    name: "Policy and Dataset Templates",
    description: "Policy and Dataset Templates",
  },
  flow_details: {
    name: "Event Details for Dashboard",
    description:
      "Dashboard details of events, including data flows with filenames, email addresses, etc.",
  },
  flow_stats: {
    name: "Event Numbers",
    description: "Aggregate number of events and related statistics",
  },
  flow_screenshots: {
    name: "Endpoint Screenshots",
    description: "Screenshots of incident violations taken on end-user endpoints",
  },
  flow_captured_content: {
    name: "File Content",
    description: "Full content of captured files",
  },
  incident_comment: {
    name: "Incident Comments",
    description: "Comments for each incident",
  },
  incident_details: {
    name: "Event Details for Incidents",
    description: "View event details for incidents",
  },
  incident: {
    name: "Incidents",
    description: "List of all incidents",
    update: { linkedWith: ["assigned_incident__update"] },
  },
  endpoint: {
    name: "Endpoint Sensor Status",
    description: "Endpoint sensors information and management",
  },
  cloud_sensor: {
    name: "Cloud Sensors",
    description: "Cloud sensors and connectors configuration",
  },
  admin_saml: {
    name: "Authentication Providers",
    description: "Cyberhaven console authentication providers configuration",
  },
  admin_global_settings: {
    name: "Logo Settings",
    description: "Configuration of logo image shown in end-user warnings",
  },
  api_token: {
    name: "API Token Management (deprecated)",
    description: "API tokens configuration",
  },
  api_keys: {
    name: "API Keys",
    description: "API keys configuration",
  },
  audit: {
    name: "Audit Log",
    description: "Audit log on RISKS OVERVIEW page",
  },
  role: {
    name: "Role Management",
    description: "Cyberhaven console roles information and management",
  },
  scope: {
    name: "Scope Management",
    description: "Cyberhaven console scopes information and management",
  },
  user: {
    name: "User Management",
    description: "Cyberhaven console users information and management",
  },
  dlp_rules: {
    name: "Content identifiers",
    description: "Content identifiers configuration",
  },
  file_tags: {
    name: "Document Tags",
    description: "Document tags configuration",
  },
  console: {
    name: "Login via UI",
    description:
      "Ability to login to Cyberhaven dashboard which gives access to favicons, short url, user defined settings and features enabled",
  },
  global_settings: {
    name: "Feature Control",
    description: "Control of optional and beta features of Cyberhaven",
  },
  global_auth_settings: {
    name: "Automatic Logout",
    description: "Force users to logout after certain period of inactivity",
  },
  directory_sync: {
    name: "Directory Sync Integration",
    description: "Directory integration information and management",
  },
  assigned_incident: {
    name: "Assigned Incidents",
    description: "Access incidents assigned to me",
    update: { linkedWith: ["incident__update"] },
  },
  visual_analytics: {
    name: "Visual Analytics",
    description: "Access to analytics dashboard and tools (kibana and other tools)",
  },
  external_storage_settings: {
    name: "External storage settings",
    description: "Access to external storage settings.",
  },
  irm: {
    name: "Insider Risk",
    description: "List of insiders with related statistics.",
  },
  edm_rules: {
    name: "Exact data matching rules",
    description: "Exact data matching rules management",
  },
  edm_db: {
    name: "Exact data matching db",
    description: "Exact data matching db management",
  },
  user_risk_group: {
    name: "User Risk Group",
    description: "Groups of risky users",
  },
  risk_score_clearing: {
    name: "Risk Score Clearing",
    description: "Access to risk score clearing",
  },
  streaming_destination: {
    name: "Integration Destination",
    description: "Destination of streamed records",
  },
  streaming_profile: {
    name: "Integration Configuration",
    description: "Configuration that streams select records to a destination",
  },
  streaming_connection_log: {
    name: "Integration Connection History",
    description: "Connection log of an integration profile",
  },
  audit_log: {
    name: "Audit Log",
    description: "Access to audit log",
  },
  pii: {
    name: "PII",
    description: "Access to PII data",
  },
  list: {
    name: "Lists",
    description: "Access to modify lists and list contents",
  },
  insights_360: {
    name: "Insights 360",
    description: "Access to the Insights 360 page",
  },
};

type SectionType = {
  title: string;
  permissions: {
    [key in PermissionResources]?: { isRequired?: boolean; requiredDescription?: string };
  };
  enabledPermissionTypes?: string[];
};

export const permissionsSections: SectionType[] = [
  {
    title: "General",
    permissions: {
      console: {
        isRequired: true,
        requiredDescription: "This permission is needed to login from Login page",
      },
      ...(ENV.FEATURES.FE_DATA_MASKING_ENABLED
        ? {
            pii: {
              requiredDescription: "This permission is needed to see PII data in the dashboard",
            },
          }
        : {}),
    },
  },
  {
    title: "Risks Dashboard",
    permissions: {
      dataset: { isRequired: true },
      policy: { isRequired: true },
      flow_stats: { isRequired: true },
      flow_details: {},
      audit: {},
      flow_captured_content: {},
      dataset_policy_template: {},
      directory_user_details: {},
      edm_rules: {},
      edm_db: {},
    },
  },
  {
    title: "Incidents",
    permissions: {
      incident: { isRequired: true },
      assigned_incident: { isRequired: true },
      incident_comment: {},
      incident_details: {},
      flow_screenshots: {},
      flow_captured_content: {},
      directory_user_details: {},
    },
  },
  {
    title: "Insider Risk",
    permissions: {
      irm: { isRequired: true },
      incident: { isRequired: true },
      dataset: { isRequired: true },
      policy: { isRequired: true },
      flow_stats: { isRequired: true },
      user_risk_group: {},
      risk_score_clearing: {},
      visual_analytics: {},
      directory_user_details: {},
    },
  },
  {
    title: "Cloud sensors",
    enabledPermissionTypes: ["read", "modify"],
    permissions: {
      cloud_sensor: { isRequired: true },
    },
  },
  {
    title: "Endpoints",
    permissions: { endpoint: { isRequired: true } },
  },
  {
    title: "Audit",
    permissions: {
      audit_log: {},
    },
  },
  {
    title: "Settings",
    permissions: {
      user: {},
      role: {},
      scope: {},
      directory_sync: {},
      global_settings: {},
      dlp_rules: {},
      file_tags: {},
      admin_global_settings: {},
      admin_saml: {},
      api_keys: {},
      api_token: {},
      global_auth_settings: {},
      list: {},
      visual_analytics: {},
      external_storage_settings: {},
      edm_rules: {},
      edm_db: {},
      ...(ENV.FEATURES.STREAMING_ENABLED
        ? {
            streaming_destination: {},
            streaming_profile: {},
            streaming_connection_log: {},
          }
        : {}),
    },
  },
];
