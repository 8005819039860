/* eslint-disable */
// prettier-disable
export const permissions = [
  "admin_saml__modify",
  "admin_saml__read",
  "api_token__modify",
  "api_token__read",
  "api_keys__read",
  "api_keys__modify",
  "console__read",
  "global_auth_settings__modify",
  "global_auth_settings__read",
  "global_settings__modify",
  "global_settings__read",
  "incident_comment__modify",
  "incident_comment__read",
  "irm__read",
  "role__create",
  "role__delete",
  "role__read",
  "role__update",
  "user__create",
  "user__delete",
  "user__read",
  "user__update",
  "admin_global_settings__modify",
  "admin_global_settings__read",
  "audit__read",
  "cloud_sensor__modify",
  "cloud_sensor__read",
  "dataset__create",
  "dataset__delete",
  "dataset__read",
  "dataset__update",
  "dataset_policy_template__read",
  "directory_sync__modify",
  "directory_sync__read",
  "directory_user_details__read",
  "dlp_rules__modify",
  "dlp_rules__read",
  "endpoint__read",
  "endpoint__update",
  "external_storage_settings__modify",
  "external_storage_settings__read",
  "file_tags__modify",
  "edm_rules__modify",
  "edm_rules__read",
  "edm_db__modify",
  "edm_db__read",
  "file_tags__read",
  "flow_captured_content__read",
  "flow_details__read",
  "flow_screenshots__read",
  "flow_stats__read",
  "incident__read",
  "incident__update",
  "policy__create",
  "policy__delete",
  "policy__read",
  "policy__update",
  "visual_analytics__read",
  "assigned_incident__update",
  "assigned_incident__read",
  "incident_details__read",
  "scope__create",
  "scope__delete",
  "scope__read",
  "scope__update",
  "user_risk_group__read",
  "user_risk_group__create",
  "user_risk_group__update",
  "user_risk_group__delete",
  "risk_score_clearing__read",
  "risk_score_clearing__modify",
  "streaming_destination__read",
  "streaming_destination__create",
  "streaming_destination__update",
  "streaming_destination__delete",
  "streaming_profile__read",
  "streaming_profile__create",
  "streaming_profile__update",
  "streaming_profile__delete",
  "streaming_connection_log__read",
  "audit_log__read",
  "pii__read",
  "list__read",
  "list__modify",
  "insights_360__read",
] as const;
